import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo";
import Layout from "../components/layout"


export default function RequestDemo() {
  return (
    <Layout>
      <SEO 
        title="Request a Demo - Compute Software" 
        description="Request a demo. Compute Software's demo gives you quick view into how our solution can help your team maximize cloud cost savings, performance, and Engineering productivity, all at once."
      />
      <Helmet></Helmet>
      <section className="demo-request-form sub-general-content">
        <div className="container">
          <div className="demo-text col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <h1 className="display-4">Request a Demo</h1>
            <p>Get a quick view into how our solution works and how it can help your team maximize cloud cost savings, performance, and Engineering productivity.</p>
            <p><strong>The demo will cover core capabilities and benefits, including:</strong></p>
            <ul>
              <li>Clear optimization goals based on business metrics</li>
              <li>Prioritized, actionable optimization recommendations</li>
              <li>Properties to apply your business objectives and workload characteristics</li>
              <li>Where and when performance affects business value</li>
              <li>How discounts are accounted for to make the right tradeoffs</li>
              <li>Optimization value measurement and tracking</li>
              <li>And much more!</li>
            </ul>
          </div>
          <div className="demo-form col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <form name="demo" className="was-validated" method="POST" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
              <div className="form-row row hidden">
                <div className="form-group col-md-12">
                  <label htmlFor="source" className="form-label">Source</label>
                  <input type="text" className="form-control" id="source" name="source" value="Direct"/>
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="firstName" className="form-label">First name</label>
                  <input type="text" className="form-control" id="firstName" name="firstName" required />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="lastName" className="form-label">Last name</label>
                  <input type="text" className="form-control" id="lastName" name="lastName" required />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="email" className="form-label">Work email</label>
                  <input type="email" className="form-control" id="workEmail" name="workEmail" required />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group col-md-12">
                  <label htmlFor="phoneNumber" className="form-label">Phone number</label>
                  <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="company" className="form-label">Company</label>
                  <input type="text" className="form-control" id="company" name="company" required />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="jobTitle" className="form-label">Job title</label>
                  <input type="text" className="form-control" id="jobTitle" name="jobTitle" required />
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <label htmlFor="averageMonthlyCloudSpend" className="form-label">Average Monthly Cloud Spend</label>
                  <select id="averageMonthlyCloudSpend" name="averageMonthlyCloudSpend" className="form-control" required >
                    <option aria-label="Placeholder" value="" disabled selected></option>
                    <option>Less than $100k</option>
                    <option>$100k - $250k</option>
                    <option>$250k - $1M</option>
                    <option>More than $1M</option>
                  </select>
                </div>
              </div>
              <div className="form-row row">
                <div className="form-group required col-md-12">
                  <fieldset>
                    <legend>Which of the following public cloud providers are you using?</legend>
                    <label htmlFor="provider" className="form-label">Which of the following public cloud providers are you using?</label>
                    <ul className="list-group">
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerAWS" name="provider[]" value="AWS"/> AWS
                        </label>
                      </li>
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerGCP" name="provider[]" value="GCP"/> GCP
                        </label>
                      </li>
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerAzure" name="provider[]" value="Azure"/> Azure
                        </label>
                      </li>
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerOnPremise" name="provider[]" value="On Premise"/> On Premise
                        </label>
                      </li>
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerOtherPublicCloud" name="provider[]" value="Other public cloud"/> Other public cloud
                        </label>
                      </li>
                      <li className="list-group-item">
                        <label>
                          <input type="checkbox" className="list-control-input form-control" id="providerNone" name="provider[]" value="Not using public cloud yet"/> Not using public cloud yet
                        </label>
                      </li>
                    </ul>
                  </fieldset>
                </div>
              </div>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="demo"  />
              <button type="submit" className="form-submit btn">Submit</button>
              <span className="required-field-indicator">* Required fields.</span>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}